<template>
  <div>
    <b-card title="Liste des documents">
      <b-row>
        <b-col sm="4">
          <b-form-group label="Statut">
            <b-form-select
              v-model="status"
              plain
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="items"
        :fields="fields"
        responsive
        hover
        show-empty
        empty-text="Aucun document enrégistré"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <a
            :href="data.item.doc_uri"
            target="_blank"
          >
            <span class="cursor">
              <b-icon
                class="text-primary"
                icon="eyeglasses"
                font-scale="1.5"
              />
            </span>
          </a>
          <span
            v-if="data.item.state === 'REJECTED'"
            class="cursor ml-1"
            @click="openModal(data.item)"
          >
            <b-icon
              class="text-danger"
              icon="x-octagon"
              font-scale="1.1"
            />
          </span>
        </template>
        <template v-slot:cell(date)="data">
          <span>{{ data.item.updated_at | moment("DD/MM/YY [à] HH:mm") }}</span>
        </template>
        <template v-slot:cell(state)="data">
          <b-badge
            pill
            :variant="getVariant(data.item.state)"
          >
            {{ getName(data.item.state) }}
          </b-badge>
        </template>
      </b-table>
      <div
        v-if="!loaded && items.length > 0"
        class="mt-2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      centered
      :title="`${getDocumentName(currentDocumentStorage.doc_type)}`"
      hide-footer
    >
      <div
        v-if="currentDocumentStorage.reject_motif"
        class="text-justify mt-2 h5"
      >
        <p class="text-uppercase">
          Motif de rejet
        </p>
        {{ currentDocumentStorage.reject_motif }}
      </div>
      <hr class="my-3 bg-primary">
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      loaded: false,
      description: null,
      showModal: false,
      items: [],
      fields: [
        {
          key: 'doc_type',
          label: 'Document',
          formatter: val => this.getDocumentName(val),
        },
        { key: 'date', label: 'Date' },
        { key: 'state', label: 'Etat' },
        { key: 'action', label: 'Action' },
      ],
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      status: null,
      statusOptions: [
        { value: null, text: 'Tous les états' },
        { value: 'SUBMITTED', text: 'Soumis' },
        { value: 'TREATMENT', text: 'Traitement' },
        { value: 'VALID', text: 'Validé' },
        { value: 'REJECTED', text: 'Rejeté' },
      ],
      currentDocumentStorage: null,
    }
  },
  watch: {
    status(val) {
      if (val) {
        this.getAllDocumentsByPartnerAndState()
      } else {
        this.getAllDocuments()
      }
    },
  },
  mounted() {
    this.getAllDocuments()
  },
  methods: {
    openModal(item) {
      this.currentDocumentStorage = item
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    getVariant(item) {
      let name = null
      switch (item) {
        case 'SUBMITTED':
          name = 'primary'
          break
        case 'TREATMENT':
          name = 'warning'
          break
        case 'VALID':
          name = 'success'
          break
        case 'REJECTED':
          name = 'danger'
          break
        default:
          break
      }
      return name
    },
    getName(item) {
      let name = null
      switch (item) {
        case 'SUBMITTED':
          name = 'SOUMIS'
          break
        case 'TREATMENT':
          name = 'TRAITEMENT'
          break
        case 'VALID':
          name = 'VALIDE'
          break
        case 'REJECTED':
          name = 'REJETE'
          break
        default:
          break
      }
      return name
    },
    getDocumentName(item) {
      let name = null
      switch (item) {
        case 'ID':
          name = "PIECE D'IDENTITE"
          break
        case 'FACE_ID':
          name = "PIECE D'IDENTITE + SELFIE"
          break
        default:
          break
      }
      this.direction = name
      return name
    },

    async getAllDocuments() {
      this.loaded = true
      this.items = await this.$http
        .get(`/user-kyc-doc/find-by-user/${this.$store.getters.currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.totalItems = res.data.length
          this.loaded = false
          return res.data
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Documents a fournir',
              text: 'Une erreur est survenue.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllDocumentsByPartnerAndState() {
      this.loaded = true
      this.items = await this.$http
        .get(`/user-kyc-doc/find-by-user-and-state/${this.$store.getters.currentUser.id}/${this.status}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.totalItems = res.data.length
          this.loaded = false
          return res.data
        })
        .catch(() => {
          this.loaded = false
          this.$notify(
            'error',
            'Documents KYC',
            'Une erreur est survenue lors de la recuperation.',
            { duration: 5000, permanent: false },
          )
          return []
        })
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
</style>
